var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-menu-positioner"},[_c('button',{staticClass:"header-menu-toggler",on:{"click":function($event){return _vm.toggleHeaderMenu()}}},[_vm._v(" Menu ")]),_c('nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeaderMenu),expression:"showHeaderMenu"}],staticClass:"header-menu"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeaderMenu),expression:"showHeaderMenu"}],staticClass:"hover-shield"}),_c('ul',[_c('li',[_c('router-link',{staticClass:"header-menu-item",attrs:{"to":{name: 'manual'}}},[_vm._v(" User Manual ")])],1),_c('li',{staticClass:"create-deck"},[_c('router-link',{staticClass:"header-menu-item",attrs:{"to":{name: 'createDeck'}}},[_vm._v(" Create Deck ")])],1),_c('li',{staticClass:"deck-menu"},[_c('button',{staticClass:"deck-menu-toggler header-menu-item",attrs:{"disabled":_vm.disableMenuButton,"title":_vm.disabledMenuButtonTooltip},on:{"click":function($event){return _vm.toggleDeckMenu()}}},[_vm._v(" Open Deck… "),_c('div',{staticClass:"mouseover-area"})]),_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDeckMenu),expression:"showDeckMenu"}],staticClass:"hover-shield"}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDeckMenu),expression:"showDeckMenu"}]},_vm._l((_vm.$store.state.decks),function(deck){return _c('li',{key:deck.name},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.params.deckPath !== deck.path),expression:"$route.params.deckPath !== deck.path"}],attrs:{"to":{
								name: 'deckEditor',
								params: {
									deck: deck,
									deckPath: deck.path
								}
							}},nativeOn:{"click":function($event){return _vm.closeAllPopups()}}},[_c('span',{staticClass:"deck-menu-deck-name"},[_vm._v(_vm._s(deck.name))]),_c('div',{staticClass:"deck-menu-deck-colors"},[_c('div',{class:_vm.sizeManaSymbols(deck),domProps:{"innerHTML":_vm._s(_vm.renderManaSymbols(deck))}})])])],1)}),0)])])]),_c('bg-overlay',{on:{"closePopups":function($event){return _vm.closeAllPopups()}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"open-deck-heading"},[_c('strong',[_vm._v("Open Deck:")])])
}]

export { render, staticRenderFns }